import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {},
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuItems: (state, action) => {
            state.data = action.payload.data;
        },

        clearMenuItems: (state) => {
            state.data = null;
        },
    },
});

export const { setMenuItems, clearMenuItems } = menuSlice.actions;
export default menuSlice.reducer;
