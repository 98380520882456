import React, { useState, useEffect, useCallback } from 'react';
import { GetAllUsers, HeaderApi } from '../../../api';
import { Row, Col, Input, Button, Table, Card, FormGroup, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { formatDateTime } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const List = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);


    const fetchUserData = useCallback(async () => {
        const requestOptions = {
            method: 'POST',
            headers: HeaderApi
        };

        try {
            const response = await axios(GetAllUsers, requestOptions);
            if (Array.isArray(response.data.data)) {
                setUsers(response.data.data);
            } else {
                console.error('Expected an array but got:', typeof response.data.data);
                setUsers([]);
            }
        } catch (error) {
            console.error('Error with the Axios request:', error);
            toast.error(error.response.data.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            setUsers([]);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);


    const navigate = useNavigate();
    const navigateToAddUser = () => {
        navigate(`${process.env.PUBLIC_URL}/ums/add-user`);
    };
    const navigateToEditUser = (id) => {
        navigate(`${process.env.PUBLIC_URL}/ums/edit-user/${id}`);
    };
    const navigateToChangePassword = (id) => {
        navigate(`${process.env.PUBLIC_URL}/ums/password-management/${id}`);
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const getFilteredUsers = () => {
        return users.filter((user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.mobile.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            (user.user_status_id === 1 ? "Active" : "Closed").toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const filteredUsers = searchTerm ? getFilteredUsers() : users;

    return (
        <Col xl="12">
            <Card>
                <Col sm="12" className="d-flex justify-content-center align-items-center">
                    &nbsp;
                </Col>
                <Col sm="12" className="d-flex justify-content-center align-items-center">
                    <h2 className="text-center">User List</h2>
                </Col>
                <Row>
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <FormGroup className="d-flex justify-content-center align-items-center" style={{ width: '100%', margin: '1rem' }}>
                            <Col md={4} >
                                <Input
                                    type="text"
                                    placeholder="Type To Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </Col>
                            <Col md={2} style={{ marginLeft: "10px" }} className="d-flex justify-content-start p-0">
                                <Button color="btn-pill btn btn-success" style={{ marginLeft: 5 }} onClick={navigateToAddUser}>ADD USER</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <CardFooter>
                    <Col xs="12">
                        <Table className="dt-table1 table-striped table-responsive table-hover">
                            <thead>
                                <tr align={"center"}>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Last Login</th>
                                    <th>Created On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.length > 0 ? filteredUsers.map((user, index) => (
                                    <tr key={user.ID}>
                                        <td>{user.ID}</td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{user.user_status_id === 1 ? "Active" : "Closed"}</td>
                                        <td>{user.user_last_login_datetime ? formatDateTime(user.user_last_login_datetime) : "-"}</td>
                                        <td>{user.CreatedAt ? formatDateTime(user.CreatedAt) : "-"}</td>
                                        <td align={'center'}>
                                            <Button className="btn btn-primary btn-smbtn btn-primary" onClick={() => navigateToEditUser(user.ID)}>
                                                <i className="icofont icofont-edit"></i>
                                            </Button>
                                            <Button className="btn btn-info btn-smbtn btn-info" style={{ marginLeft: '5px' }} onClick={() => navigateToChangePassword(user.ID)}>
                                                <i className="icofont icofont-key" ></i>
                                            </Button>
                                        </td>
                                    </tr>
                                )) :
                                    <tr><td colspan="8" align={'center'} style={{ color: "red", fontSize: 15 }}>Record not found</td> </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </CardFooter>
            </Card>
        </Col>
    );
};


export default List;
