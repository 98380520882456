import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    data: {},
};

const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState,
    reducers: {
        setCustomerDetails: (state, action) => {
            state.data = action.payload;
        },
        clearCustomerDetails: (state) => {
            state.data = {};
        }
    }
});

export const {setCustomerDetails, clearCustomerDetails} = customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
