import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './../Slices/authSlice';
import menuReducer from './../Slices/menuSlice';
import masterReducer from './../Slices/mastersSlice';
import customerDetailsReducer from './../Slices/customerDetailsSlice';


const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  masters: masterReducer,
  customerDetails: customerDetailsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'menu', 'masters', 'customerDetails'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);
