import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    name: null,
    token: null,
    roles: [],
    user: {},
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload.user;
            state.name = action.payload.name;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            state.userId = action.payload.userId;
            state.isAuthenticated = true;
        },

        logout: (state) => {
            state.userId = null;
            state.name = null;
            state.token = null;
            state.roles = [];
            state.user = {};
            state.isAuthenticated = true;
        },
    },
});

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
