import React, { useState, useEffect, useCallback } from 'react';
import { Col, Card, Row, Table, Button, FormGroup, CardFooter } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { HeaderApi, RolesListByUserId, GetAllUsers, UpdateRole } from '../../../api';
import Select from "react-select";

const AssignRoles = () => {
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedRoles, setSelectedRoles] = useState({});
    const super_admin = localStorage.getItem('super_admin');

    const fetchRoles = useCallback(async () => {
        if (!selectedUserId) {
            setRoles([]);
            setSelectedRoles({});
            return;
        }

        try {
            const response = await axios.get(`${RolesListByUserId}${selectedUserId}`, { headers: HeaderApi });
            setRoles(response.data.data || []);
            const newSelectedRoles = response.data.data.reduce((acc, role) => ({
                ...acc,
                [role.id]: !!role.role_id,
            }), {});
            setSelectedRoles(newSelectedRoles);
        } catch (error) {
            toast.error("Error fetching roles", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    }, [selectedUserId]);

    useEffect(async () => {
        try {
            const response = await axios.post(GetAllUsers, undefined, { headers: HeaderApi });
            const userdata = response.data.data.map(user => ({
                label: user.name + " - " + user.email,
                value: user.ID
            }));
            setUsers(userdata);
        } catch (error) {
            toast.error("Failed to fetch users" + error, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    }, []);

    const handleSubmit = async () => {
        const selectedRoleIds = Object.keys(selectedRoles).filter(roleId => selectedRoles[roleId]);
        console.log(selectedRoleIds)
        try {
            await axios.put(UpdateRole, {
                user_id: parseInt(selectedUserId, 10),
                type_id: selectedRoleIds
            }, { headers: HeaderApi });
            toast.success("Roles updated successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            fetchRoles();
        } catch (error) {
            toast.error("Failed to update roles", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    };

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    const handleUserChange = async (selectedOption, { name }) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        if (selectedValue) setSelectedUserId(selectedValue);
    };

    const handleRoleSelect = useCallback((roleId) => {
        setSelectedRoles(prevSelectedRoles => ({
            ...prevSelectedRoles,
            [roleId]: !prevSelectedRoles[roleId]
        }));
    }, []);

    return (
        <Col sm="12">
            <Card>
                <Row className="card-block justify-content-center align-items-center">
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <h2 className="text-center">Assign User Roles</h2>
                    </Col>
                    <Col sm="8" className="d-flex justify-content-center align-items-center">
                        <FormGroup style={{ flex: 1, marginRight: '1rem', marginTop: '1rem', marginLeft: '1rem' }}>
                            <Select
                                className="basic-single"
                                value={users.find(user => user.value === selectedUserId)}
                                isSearchable={true}
                                name="user_id"
                                id="userSelect"
                                options={users}
                                defaultOptions
                                onChange={(selectedOption) => handleUserChange(selectedOption, { name: 'user_id' })}
                            />
                        </FormGroup>
                        <Button className={"btn-pill btn btn-success"} onClick={handleSubmit} style={{ whiteSpace: 'nowrap' }}>
                            Update Roles
                        </Button>
                    </Col>
                    <Col xl="12">
                        <CardFooter xl="10">
                            <div className="table-responsive">
                                <Table hover striped>
                                    <thead className="table-success">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Header</th>
                                            <th scope="col">Sub Header</th>
                                            <th scope="col">Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roles.length > 0 ? roles.map((role) => {

                                            if (super_admin == "false" && role.id == 1) return null;

                                            return (
                                                <tr key={role.id}>
                                                    <td>{role.id}</td>
                                                    <td>{role.name}</td>
                                                    <td>{role.sub_name}</td>
                                                    <td>
                                                        <Button
                                                            color={selectedRoles[role.id] ? 'success' : 'secondary'}
                                                            onClick={() => handleRoleSelect(role.id)}
                                                        >
                                                            {selectedRoles[role.id] ? 'Selected' : 'Select'}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        }) : (
                                            <tr>
                                                <td colSpan="4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </Table>
                            </div>

                        </CardFooter>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

export default AssignRoles;
