import CommingSoon from '../Component/Pages/ComingSoon/ComingBgImg';
import InternalServerError from '../Component/Pages/ErrorPages/internal-server-error';
import PageNotFound from '../Component/Pages/ErrorPages/pagenot-found';
import UnlockUser from '../Component/Pages/Auth/UnlockUser';
import ForgetPwd from '../Component/Pages/Auth/ForgetPwd';
import CreatePwd from '../Component/Pages/Auth/CreatePwd';
import Maintenance from '../Component/Pages/Auth/Maintenance';
import ForgotPassword from '../Component/Users/UMS/ForgotPassword';

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/screen/unlock-user`, Component: <UnlockUser /> },
  { path: `${process.env.PUBLIC_URL}/users/forget-pwd`, Component: <ForgetPwd /> },
  { path: `${process.env.PUBLIC_URL}/users/create-pwd`, Component: <CreatePwd /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`, Component: <Maintenance /> },
  { path: `${process.env.PUBLIC_URL}/forgot-password`, Component: <ForgotPassword /> },

  //Coming soon
  { path: `${process.env.PUBLIC_URL}/coming-soon`, Component: <CommingSoon /> },

  //Error Page
  { path: `${process.env.PUBLIC_URL}/internal-server-error`, Component: <InternalServerError /> },
  { path: `${process.env.PUBLIC_URL}/pagenot-found`, Component: <PageNotFound /> },
];
