import React from 'react';
import { useEffect } from 'react';
const Verification = ({ lead_id }) => {
  const cellStyle = {
    width: '25%'
  };


  return (
    <>
      <table className="table table-bordered table-hover" style={{ tableLayout: 'fixed', width: '100%', marginTop: "10px" }}>
        <tbody>
          <tr>
            <th style={cellStyle} scope="row">Lead Id</th>
            <td style={cellStyle} >{lead_id}</td>
            <th style={cellStyle} scope="row">Loan Number</th>
            <td style={cellStyle}></td>
          </tr>

        </tbody>
      </table>
    </>
  );
};

export default Verification;
