import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { ApplicationLogs, HeaderApi } from '../../api';
import { toast } from 'react-toastify';
import { mask_mobile, mask_email, formatDateTime, formatDate } from '../../utils';

const ApplicationDataLogs = ({ lead_id }) => {
  const [data, setData] = useState([]);
  const cellStyle = {
    width: '25%'
  };

  const fetchLogsList = async () => {
    let apiUrl = `${ApplicationLogs}/${lead_id}`;
    try {
      const response = await axios.get(apiUrl, { headers: HeaderApi });
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching state list:', error);
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    fetchLogsList();
  }, [lead_id]);

  return (
    <>
      <table style={{ marginTop: 10 }} className="table table-hover table-striped table-bordered">
        <thead>
          <tr className="table-primary">
            <th scope="col">#</th>
            <th scope="col"><b>Date Time</b></th>
            <th scope="col"><b>Status</b></th>
            <th scope="col"><b>User&nbsp;Name</b></th>
            <th scope="col"><b>Remarks</b></th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {data.length > 0 ? data.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.CreatedAt ? formatDateTime(data.CreatedAt) : "-"}</td>
              <td>{data.lead_followup_status.status_name ? data.lead_followup_status.status_name : "-"}</td>
              <td>{data.user.name ? data.user.name : "-"}</td>
              <td dangerouslySetInnerHTML={{ __html: data.remarks || "-" }} ></td>
            </tr>
          )) : (
            <tr>
              <td colSpan="8" align={'center'} style={{ color: "#f40606" }}>No data available</td>
            </tr>
          )}
        </tbody>
      </table >
    </>
  );
};

export default ApplicationDataLogs;
