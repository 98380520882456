import React, { useEffect } from 'react';
import Loader from '../Layout/Loader';

const Callback = () => {

  var isLoggedIn = localStorage.getItem('authenticated');
  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem('authenticated', true);
      window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    }
  });

  return (
    <div>
      <Loader />
    </div>
  );

};

export default Callback;
