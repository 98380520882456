import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Form, FormGroup, Input, Label, Row, Col, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { HeaderApi, Disbursed, GetLoanDetails } from '../../api';
import { formatDateYYYYMMDD, formatDate, formatDateTime } from '../../utils';
import { toast } from 'react-toastify';
import { fetchPersonalData, fetchCAMData, fetchBankingData } from '../Task/Tasks';

const cellStyle = {
  width: '25%'
};


const Disbursal = ({ lead_id }) => {
  const [data, setData] = useState({});
  const [leaddata, setLeadData] = useState({});
  const [camData, setCAMData] = useState({});
  const [loanDetails, setLoanDetails] = useState({});
  const [bankingData, setBankingData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    channel: "",
    disbursal_reference_no: "",
  });

  const convertFormData = (data) => ({
    ...data,
    lead_id: parseInt(lead_id, 10),
    disbursal_date: formatDateYYYYMMDD(camData.disbursal_date),
    channel_id: parseInt(data.channel, 10),
    payment_mode_id: 1,
    bank_id: 1,
  });

  const fetchData = useCallback(async () => {
    try {

      const personalData = await fetchPersonalData(lead_id);
      const camData = await fetchCAMData(lead_id);
      const bankingData = await fetchBankingData(lead_id);

      setData(() => {
        try {
          return personalData.data || {};
        } catch (error) {
          console.error('Error parsing personal_data from localStorage:', error);
          return {};
        }
      });
      setLeadData(() => {
        try {
          return personalData.data.lead || {};
        } catch (error) {
          console.error('Error parsing lead_data from localStorage:', error);
          return {};
        }
      });

      setCAMData(() => {
        try {
          return camData.data || {};
        } catch (error) {
          console.error('Error parsing cam_dataDataString from localStorage:', error);
          return {};
        }
      });

      setBankingData(() => {
        try {
          return bankingData.data || {};
        } catch (error) {
          console.error('Error parsing cam_dataDataString from localStorage:', error);
          return {};
        }
      });

    } catch (error) {
      console.error('Error with the Axios request:', error);
      setData({});
    }
  }, [lead_id]);

  const fetchLoanDetails = async (value) => {
    if (value > 0 && leaddata.lead_status_id > 15) {
      const apiUrl = `${GetLoanDetails}${value}`;
      try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        setLoanDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching state list:', error);
        toast.error(error.response?.data?.error || 'Error occurred', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    fetchLoanDetails(lead_id);
  }, [leaddata.lead_status_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const navigate = useNavigate();
  const navigateToDisbursalPending = () => {
    navigate(`${process.env.PUBLIC_URL}/disburse-pending`);
  };

  const onEditSubmit = async (e) => {
    e.preventDefault();
    const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
    setFormErrors(errors);
    if (!Object.values(errors).some(error => error)) {
      var convertedData = convertFormData(formData);
      let apiUrl = `${Disbursed}`;
      try {
        const response = await axios.post(apiUrl, convertedData, { headers: HeaderApi });
        navigateToDisbursalPending();
        toast.success("Disbursed Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        fetchData();
      } catch (error) {
        toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.target.value = value;

    setFormData((formData) => ({
      ...formData,
      [name]: value
    }));

    setFormErrors((errors) => ({
      ...errors,
      [name]: validateField(name, value)
    }));
  };

  const validateField = (name, value) => {
    const valueString = value.toString().trim();
    switch (name) {
      case 'channel': return valueString ? '' : 'Channel is required';
      case 'disbursal_reference_no': return valueString ? '' : 'Disbursal Reference Number is required';
      default: return '';
    }
  };

  const renderInput = useCallback((key, value) => {
    switch (key) {
      case "salary_date_1":
        return (
          <Input
            id={key}
            type="text"
            name={key}
            value={value}
            invalid={!!formErrors[key]}
            onChange={handleInputChange}
          />
        );

      case "dedupe_check":
        return (
          <Input id="dedupe_check" name="dedupe_check" type="select" value={formData.dedupe_check} invalid={!!formErrors['dedupe_check']} onChange={handleInputChange}>
            <option value="">Select Dedupe Check</option>
            <option value="1">YES</option>
            <option value="2">NO</option>
          </Input>
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            defaultValue={value}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange]);

  const renderFormGroups = Object.entries(formData).reduce((acc, entry, index, array) => {
    if (index % 2 === 0) {
      acc.push(array.slice(index, index + 2));
    }
    return acc;
  }, []).map((pair, index) => (
    <FormGroup row key={index}>
      {pair.map(([key, value]) => (
        <Fragment key={key}>
          <Label for={key} sm={2}>
            {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
          </Label>
          <Col sm={4}>
            {renderInput(key, value)}
            {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
          </Col>
        </Fragment>
      ))}
    </FormGroup>
  ));

  return (
    <Fragment>
      <div className="edit-profile" style={{ marginTop: 10 }}>
        <Row>
          <Col xl="12">
            <Card>
              <Form onSubmit={onEditSubmit}>
                <CardBody>
                  <Fragment>
                    <Row>
                      <table className="table table-bordered table-hover table-striped" style={{ marginTop: "10px" }}>
                        <tbody>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Lead Id</th>
                            <td style={cellStyle}>{leaddata.ID ? leaddata.ID : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Loan No</th>
                            <td style={cellStyle}>{leaddata.loan_no ? leaddata.loan_no : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Customer Name</th>
                            <td style={cellStyle}>{leaddata.first_name ? leaddata.first_name + " " + leaddata.middle_name + " " + leaddata.surname : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Tenure (Days)</th>
                            <td style={cellStyle}>{camData.tenure ? camData.tenure : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Loan Amount</th>
                            <td style={cellStyle}>{camData.loan_recommended ? camData.loan_recommended : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Repayment Amount</th>
                            <td style={cellStyle}>{camData.repayment_amount ? camData.repayment_amount : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">PF %</th>
                            <td style={cellStyle}>{camData.admin_fee ? camData.admin_fee : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">GST Amount</th>
                            <td style={cellStyle}>{camData.admin_fee_gst_amount ? camData.admin_fee_gst_amount : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Total Deduction (Inc GST)</th>
                            <td style={cellStyle}>{camData.total_admin_fee_amount ? camData.total_admin_fee_amount : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Amount to be disbursed</th>
                            <td style={cellStyle}>{camData.net_disbursal_amount ? camData.net_disbursal_amount : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Screener Recommended By</th>
                            <td style={cellStyle}>{leaddata.screener_assign_user?.name || "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Screener Recommended Date</th>
                            <td style={cellStyle}>{leaddata.screener_recommend_datetime != "0001-01-01T00:00:00Z" ? formatDateTime(leaddata.screener_recommend_datetime) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Sanction Recommended By</th>
                            <td style={cellStyle}>{leaddata.credit_assign_user?.name || "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Sanction Recommended Date</th>
                            <td style={cellStyle}>{leaddata.credit_recommend_datetime != "0001-01-01T00:00:00Z" ? formatDateTime(leaddata.credit_recommend_datetime) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Final Approved By</th>
                            <td style={cellStyle}>{leaddata.credit_approve_user?.name || "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Final Approved Date</th>
                            <td style={cellStyle}>{leaddata.disbursal_approve_datetime != "0001-01-01T00:00:00Z" ? formatDateTime(leaddata.credit_approve_datetime) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Disbursal Checked By</th>
                            <td style={cellStyle}>{leaddata.disbursal_assign_user?.name || "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Disbursal Checked Date</th>
                            <td style={cellStyle}>{leaddata.disbursal_assign_datetime != "0001-01-01T00:00:00Z" ? formatDateTime(leaddata.disbursal_assign_datetime) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Loan Disbursed By</th>
                            <td style={cellStyle}>{leaddata.disbursal_approve_user?.name || "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Loan Disbursed DateTime</th>
                            <td style={cellStyle}>{leaddata.disbursal_approve_datetime != "0001-01-01T00:00:00Z" ? formatDateTime(leaddata.disbursal_approve_datetime) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Disbursal Date</th>
                            <td style={cellStyle}>{camData.disbursal_date ? formatDate(camData.disbursal_date) : "-"}</td>
                            <th style={cellStyle} className="text-nowrap" scope="row">Repayment Date</th>
                            <td style={cellStyle}>{camData.repayment_date ? formatDate(camData.repayment_date) : "-"}</td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Payable Account No.</th>
                            <td style={cellStyle}>{loanDetails.disbursement_bank?.name || "-"}</td>
                            <th className="text-nowrap" style={cellStyle} scope="row">Channel</th>
                            <td style={cellStyle}>
                              {leaddata.stage === "S14" && (
                                <>
                                  {loanDetails.channel?.name || "-"}
                                </>
                              ) || leaddata.lead_status_id == 20 && (
                                <>
                                  <div className="input-button-container" style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Input type="select" name="channel" value={formData.channel} style={{ paddingRight: '2.5rem' }} onChange={handleInputChange} invalid={!!formErrors.channel}>
                                      <option value="">Select</option>
                                      <option value={1}>NEFT</option>
                                      <option value={2}>IMPS</option>
                                      <option value={3}>RTGS</option>
                                      <option value={4}>UPI</option>
                                    </Input>
                                  </div>
                                  {formErrors["channel"] && <FormFeedback>{formErrors["channel"]}</FormFeedback>}
                                </>
                              ) || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-nowrap" style={cellStyle} scope="row">Disbursal Status</th>
                            <td style={cellStyle}>{leaddata.status ? leaddata.status : "-"}</td>
                            <th className="text-nowrap" style={cellStyle} scope="row">Reference No.</th>
                            <td style={cellStyle}>
                              {leaddata.stage === "S14" && (
                                <>
                                  {loanDetails?.disbursal_reference_no || "-"}
                                </>
                              ) || leaddata.lead_status_id == 20 && (
                                <>
                                  <div className="input-button-container" style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <Input type="text" name="disbursal_reference_no" value={formData.disbursal_reference_no} invalid={!!formErrors.disbursal_reference_no} style={{ paddingRight: '2.5rem' }} onChange={handleInputChange} />
                                    <Button type={"submit"} className="btn btn-outline-info" style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)', zIndex: '2' }}>
                                      <i className="icofont icofont-ui-check"></i>
                                    </Button>
                                  </div>
                                </>
                              ) || "-"}
                            </td>
                          </tr>

                          {bankingData.length > 0 ? bankingData.map((item, index) => (
                            item.account_status_id === 1 && (
                              <>
                                <br />
                                <tr>
                                  <td align={"center"} colSpan={4} style={{ fontSize: 20 }}><strong>Disbursal Account</strong></td>
                                </tr>
                                <br />

                                <tr>
                                  <th className="text-nowrap" style={cellStyle} scope="row">Beneficiary Name</th>
                                  <td style={cellStyle}>{item.beneficiary_name ? item.beneficiary_name : "-"}</td>
                                  <th style={cellStyle} className="text-nowrap" scope="row">Branch Name</th>
                                  <td style={cellStyle}>{item.branch ? item.branch : "-"}</td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" style={cellStyle} scope="row">Bank Account No.</th>
                                  <td style={cellStyle}>{item?.account || "-"}</td>
                                  <th style={cellStyle} className="text-nowrap" scope="row">IFSC Code</th>
                                  <td style={cellStyle}>{item.ifsc_code ? item.ifsc_code : "-"}</td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" style={cellStyle} scope="row">Bank Account Type</th>
                                  <td style={cellStyle}>{item.account_type.name ? item.account_type.name : "-"}</td>
                                  <th style={cellStyle} className="text-nowrap" scope="row">Bank Name</th>
                                  <td style={cellStyle}>{item.bank_name ? item.bank_name : "-"}</td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" style={cellStyle} scope="row">Verified On</th>
                                  <td style={cellStyle}>{item.UpdatedAt ? formatDateTime(item.UpdatedAt) : "-"}</td>
                                  <th style={cellStyle} className="text-nowrap" scope="row">Verification Status</th>
                                  <td style={{ ...cellStyle, color: "green" }}>{item.account_status.name ? item.account_status.name : "-"}</td>
                                </tr>
                              </>
                            ))) : (
                            null)}
                        </tbody>
                      </table>
                    </Row>
                  </Fragment>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Disbursal;
