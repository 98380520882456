import React, { Fragment } from 'react';
import './App.css';
import Routers from './Routes';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/customizer/CustomizerProvider';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/Store';

function App() {
      return (
            <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                        <Fragment>
                              <CustomizerProvider>
                                    <AnimationThemeProvider>                                                                                    <Routers />
                                    </AnimationThemeProvider>
                              </CustomizerProvider>
                        </Fragment >
                  </PersistGate>
            </Provider >
      );
}
export default App;
