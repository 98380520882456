import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { EditProfile, Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, FirstName, MiddleName, LastName, Address, EmailAddress, PostalCode, Country, City } from '../../../Constant';

const EditMyProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onEditSubmit = (data) => {
    alert('Form is Submitted');
  };
  const [name, setName] = useState("");
  const [ipaddress, setipaddress] = useState("");

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("user_data"));
    setName(userData.name);
    setipaddress(userData.user_static_ip);
  }, []);

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{EditProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">{FirstName}</Label>
                <input className="form-control" name='FirstName' type="text" placeholder={name} {...register('FirstName', { required: true })} /><span style={{ color: 'red' }}>{errors.FirstName && 'FirstName is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">{MiddleName}</Label>
                <input className="form-control" name='MiddleName' type="text" placeholder="Company" {...register('MiddleName')} /><span style={{ color: 'red' }}>{errors.FirstName && 'FirstName is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">{LastName}</Label>
                <input className="form-control" type="text" name='LastName' placeholder="Last Name" {...register('LastName', { required: true })} /><span style={{ color: 'red' }}>{errors.LastName && 'LastName is required'} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="6">
              <FormGroup><Label className="form-label">Registed IP</Label>
                <input className="form-control" type="text" name='IPAddress' placeholder={ipaddress} disabled />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{UpdateProfile}</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;
