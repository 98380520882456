import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Label, Media, Row, Col, CardFooter, Container, Button, FormFeedback } from 'reactstrap';
import axios from 'axios';
import user from '../../assets/images/user/7.jpg';
import { GetPersonalDetails, GetProfilePicture, HeaderApi } from '../../api';
import { formatDate, mask_mobile, mask_email, arraysHaveAnyCommonElements } from '../../utils';
import { toast } from 'react-toastify';
import { fetchPersonalData } from '../Task/Tasks';
import { useDispatch } from 'react-redux';
import { setCustomerDetails } from '../../Redux/Slices/customerDetailsSlice';
import { useSelector } from 'react-redux';

const cellStyle = {
  width: '25%'
};
const Personal = ({ lead_id }) => {

  const [data, setData] = useState({});
  const [leaddata, setLeadData] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [residenceState, setResidenceState] = useState({});
  const [residenceCity, setResidenceCity] = useState({});
  const [enduse, setEnduse] = useState({});
  const [maritalStatus, setMaritalStatus] = useState({});
  const [spouseOccupation, setSpouseOccupation] = useState({});
  const [residenceType, setresidenceType] = useState({});
  const [qualification, setQualification] = useState({});
  const [religion, setReligion] = useState({});
  const [fileURL, setFileURL] = useState('');
  const roleIds = useSelector(state => state.auth.roles);
  const userID = useSelector(state => state.auth.userId);
  const [maskingFlag, setMaskingFlag] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      const responseData = await fetchPersonalData(lead_id);
      setData(responseData.data);
      dispatch(setCustomerDetails(responseData.data));

      setLeadData(responseData.data.lead);
      setState(responseData.data.lead.state);
      setCity(responseData.data.lead.city);
      setReligion(responseData.data.religion);
      setResidenceState(responseData.data.residence_state);
      setResidenceCity(responseData.data.residence_city);
      setEnduse(responseData.data.lead.enduse);
      setMaritalStatus(responseData.data.marital_status);
      setSpouseOccupation(responseData.data.spouse_occupation);
      setQualification(responseData.data.qualification);
      setresidenceType(responseData.data.residence_type);
      if (arraysHaveAnyCommonElements(roleIds, [3, 4]) && [4, 5, 7, 8, 9].includes(responseData.data.lead?.lead_status_id) && (userID === responseData.data.lead?.screener_assign_user_id || userID === responseData.data.lead?.credit_assign_user_id)) {
        setMaskingFlag(true);
      } else if (arraysHaveAnyCommonElements(roleIds, [1, 2])) {
        setMaskingFlag(true);
      }

    } catch (error) {
      console.error('Error fetching or parsing personal_data:', error);
    }
  }, [lead_id]);


  useEffect(() => {
    fetchData();
    handleView(lead_id);
  }, [fetchData, lead_id]);

  const handleView = async lead_id => {
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
      setFileURL('');
    }
    try {
      const response = await axios.get(`${GetProfilePicture}${parseInt(lead_id, 10)}`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob(
        [response.data],
        { type: contentType });

      const fileURL = URL.createObjectURL(file);

      setFileURL(fileURL);
    } catch (error) {
      toast.error('Profile picture not found:', error);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="edit-profile" style={{ marginTop: "10px" }}>
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <Form>
                    <div className="profile-title d-flex justify-content-between align-items-center">
                      <Media>
                        <img src={fileURL ? fileURL : user} alt="" className="img-70 rounded-circle" />
                        <Media body>
                          <h3 attrh3={{ className: 'mb-1 f-20 txt-primary' }}>{leaddata.first_name ? leaddata.first_name + " " + leaddata.middle_name + " " + leaddata.surname : "-"}</h3>
                          <p>{localStorage.getItem('customer_designation') ? localStorage.getItem('customer_designation') : "-"}</p>
                        </Media>
                      </Media>
                    </div>
                    <table className="table table-bordered table-hover table-striped" >
                      <tbody>
                        <tr>
                          <th className="text-nowrap" scope="row">First Name</th>
                          <td>{leaddata.first_name ? leaddata.first_name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Middle Name</th>
                          <td>{leaddata.middle_name ? leaddata.middle_name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Last Name</th>
                          <td>{leaddata.surname ? leaddata.surname : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Qualification</th>
                          <td>{qualification.name ? qualification.name : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Gender</th>
                          <td>{data.gender === 1 ? "MALE" : data.gender === 2 ? "FEMALE" : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">DOB</th>
                          <td>{data.dob ? formatDate(data.dob) : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Mobile</th>
                          <td>{maskingFlag ? leaddata.mobile : mask_mobile(leaddata.mobile)}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Email</th>
                          <td>{maskingFlag ? leaddata.email : mask_email(leaddata.email)}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">CIF Number</th>
                          <td>{leaddata.status ? leaddata.customer_id : "-"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap" scope="row">Status</th>
                          <td>{leaddata.status ? leaddata.status : "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Card>
                <Form>
                  <CardBody>
                    <Fragment>
                      <Row>
                        <table className="table table-bordered table-hover table-striped" style={{ marginTop: "10px" }}>
                          <tbody>
                            <tr>
                              <th className="text-nowrap" style={cellStyle} scope="row">Lead Id</th>
                              <td style={cellStyle}>{leaddata.ID ? leaddata.ID : "-"}</td>
                              <th style={cellStyle} className="text-nowrap" scope="row">Loan No</th>
                              <td style={cellStyle} >{leaddata.loan_no ? leaddata.loan_no : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">User Type</th>
                              <td>{leaddata.user_type ? leaddata.user_type : "-"}</td>
                              <th className="text-nowrap" scope="row">PAN</th>
                              <td>{leaddata.pancard ? leaddata.pancard : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Alternate Mobile</th>
                              <td>{maskingFlag ? leaddata?.alternate_mobile : mask_mobile(leaddata?.alternate_mobile)}</td>
                              <th className="text-nowrap" scope="row">Official Email</th>
                              <td>{maskingFlag ? leaddata.alternate_email : mask_email(leaddata.alternate_email)}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Religion</th>
                              <td>{religion.name ? religion.name : "-"}</td>
                              <th className="text-nowrap" scope="row">Monthly Income</th>
                              <td>{leaddata.emp_monthly_income ? leaddata.emp_monthly_income : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Loan Applied</th>
                              <td>{leaddata.applied_amount ? leaddata.applied_amount : "-"}</td>
                              <th className="text-nowrap" scope="row">Purpose</th>
                              <td>{enduse?.name || "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">State</th>
                              <td>{state.name ? state.name : "-"}</td>
                              <th className="text-nowrap" scope="row">City</th>
                              <td>{city.name ? city.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Pincode</th>
                              <td>{leaddata.pincode ? leaddata.pincode : "-"}</td>
                              <th className="text-nowrap" scope="row">Source</th>
                              <td>{leaddata.source ? leaddata.source : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">UTM Source</th>
                              <td>{leaddata.source ? leaddata.source : "-"}</td>
                              <th className="text-nowrap" scope="row">Campaign</th>
                              <td>{leaddata.utm_source ? leaddata.utm_source : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Marital Status</th>
                              <td>{maritalStatus.name ? maritalStatus.name : "-"}</td>
                              <th className="text-nowrap" scope="row">Spouse Occupation</th>
                              <td>{spouseOccupation.name ? spouseOccupation.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">IP Address</th>
                              <td>{leaddata.ip ? leaddata.ip : "-"}</td>
                              <th className="text-nowrap" scope="row">Residence Line 1</th>
                              <td>{data.residence_address1 ? data.residence_address1 : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Residence Line 2</th>
                              <td>{data.residence_address2 ? data.residence_address2 : "-"}</td>
                              <th className="text-nowrap" scope="row">Residence Line 3</th>
                              <td>{data.residence_address3 ? data.residence_address3 : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Residence Landmark</th>
                              <td>{data.residence_landmark ? data.residence_landmark : "-"}</td>
                              <th className="text-nowrap" scope="row">Residence Line 3</th>
                              <td>{residenceState.name ? residenceState.name : "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Residence Landmark</th>
                              <td>{residenceCity.name ? residenceCity.name : "-"}</td>
                              <th className="text-nowrap" scope="row">Residence Pincode</th>
                              <td>{data?.residence_pincode || "-"}</td>
                            </tr>
                            <tr>
                              <th className="text-nowrap" scope="row">Residence Type</th>
                              <td>{residenceType.name ? residenceType.name : "-"}</td>
                              <th className="text-nowrap" scope="row">Residence Since</th>
                              <td>{data.residence_since ? formatDate(data.residence_since) : "-"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Row>
                    </Fragment>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Personal;
