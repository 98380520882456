import React, { Fragment, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CustomContext from '../../../_helper/customizer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ListOfMenu = ({
    searchToggle,
    searchBar,
    searchValue,
    removeFix,
    searchResult
}) => {
    const { IsOpen } = useContext(CustomContext);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedResults = useMemo(() => {
        const sortableItems = [...searchResult];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                const aValue = sortConfig.key === 'loan_no' ? parseInt(a[sortConfig.key], 10) || 0 : a[sortConfig.key];
                const bValue = sortConfig.key === 'loan_no' ? parseInt(b[sortConfig.key], 10) || 0 : b[sortConfig.key];
                if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
                return 0;
            });
        }
        return sortableItems;
    }, [searchResult, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig.key) return;
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
        <Fragment>
            <div className={`${searchBar ? `Typeahead-menu custom-scrollbar ${searchToggle ? 'is-open' : ''}` : `filled-bookmark Typeahead-menu ${IsOpen ? 'is-open' : ''} custom-scrollbar`}`} id="search-outer">
                {searchValue && searchResult.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th onClick={() => requestSort('id')} className={getClassNamesFor('id')}>
                                    Lead ID&nbsp;
                                    <i className={`fas fa-sort-${getClassNamesFor('id') === 'ascending' ? 'up' : 'down'}`}></i>
                                </th>
                                <th onClick={() => requestSort('first_name')} className={getClassNamesFor('first_name')}>
                                    Name&nbsp;
                                    <i className={`fas fa-sort-${getClassNamesFor('first_name') === 'ascending' ? 'up' : 'down'}`}></i>
                                </th>
                                <th onClick={() => requestSort('loan_no')} className={getClassNamesFor('loan_no')}>
                                    Loan Number&nbsp;
                                    <i className={`fas fa-sort-${getClassNamesFor('loan_no') === 'ascending' ? 'up' : 'down'}`}></i>
                                </th>
                                <th onClick={() => requestSort('status')} className={getClassNamesFor('status')}>
                                    Status&nbsp;
                                    <i className={`fas fa-sort-${getClassNamesFor('status') === 'ascending' ? 'up' : 'down'}`}></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedResults.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Link
                                            to={`lead-details/${data.id}/${data.lead_status_id || '#'}`}
                                            className="realname"
                                            onClick={removeFix}
                                        >
                                            {data.id || 'No Title'}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`lead-details/${data.id}/${data.lead_status_id || '#'}`}
                                            className="realname"
                                            onClick={removeFix}
                                        >
                                            {data.first_name || '-'}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`lead-details/${data.id}/${data.lead_status_id || '#'}`}
                                            className="realname"
                                            onClick={removeFix}
                                        >
                                            {data.loan_no || '-'}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`lead-details/${data.id}/${data.lead_status_id || '#'}`}
                                            className="realname"
                                            onClick={removeFix}
                                        >
                                            {data.status || '-'}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No results found</div>
                )}
            </div>
        </Fragment>
    );
};

ListOfMenu.propTypes = {
    searchToggle: PropTypes.bool.isRequired,
    searchBar: PropTypes.bool.isRequired,
    searchValue: PropTypes.string.isRequired,
    removeFix: PropTypes.func.isRequired,
    searchResult: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ListOfMenu;
