import React, { useState } from 'react';
import { Form, Col, Card, CardFooter, Row, FormGroup, Label, Input, CardBody } from 'reactstrap';
import { HeaderApi, CreateUser } from '../../../api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatDateYYYYMMDD } from '../../../utils';

const AddUserForm = () => {
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        user_status_id: '',
        gender: '',
        father_name: '',
        marital_status: '',
        dob: '',
        user_ip_check: '',
    });

    const convertFormData = (data) => ({
        ...data,
        user_status_id: parseInt(data.user_status_id, 10),
        mobile: parseInt(data.mobile, 10),
        user_ip_check: parseInt(data.user_ip_check, 10),
    });

    const createUser = async (data) => {
        const convertedData = convertFormData(data);
        try {
            const response = await axios.post(CreateUser, convertedData, { headers: HeaderApi });
            toast.success("User Created Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            navigateToUMS();
        } catch (error) {
            toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    };

    const navigateToUMS = () => navigate(`${process.env.PUBLIC_URL}/ums/user-list`);

    const validateField = (name, value) => {
        const valueString = value.toString().trim();
        switch (name) {
            case 'name': return valueString ? '' : 'Name is required';
            case 'father_name': return valueString ? '' : 'Father Name is required';
            case 'gender': return valueString ? '' : 'Gender is required';
            case 'dob': return valueString ? '' : 'DOB is required';
            case 'user_status_id': return valueString ? '' : 'Status is required';
            case 'marital_status': return valueString ? '' : 'Marital Status is required';
            case 'user_ip_check': return valueString ? '' : 'IP Check is required';
            case 'dob': return valueString ? '' : 'DOB is required';
            case 'email': return valueString ? (/\S+@\S+\.\S+/.test(valueString) ? '' : 'Email is invalid') : 'Email is required';
            case 'mobile': return valueString ? (/^\d{10}$/.test(valueString) ? '' : 'Mobile must be 10 digits') : 'Mobile is required';
            default: return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({ ...formData, [name]: value.toUpperCase() }));
        setFormErrors(errors => ({ ...errors, [name]: validateField(name, value) }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
        setFormErrors(errors);

        if (!Object.values(errors).some(error => error)) {
            createUser(formData);
        }
    };

    return (

        <Col sm="12">
            <Form onSubmit={handleSubmit}>
                <Card>
                    <Row className="justify-content-center align-items-center">
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            <h2 className="text-center">Add User</h2>
                        </Col>
                        <Col xl="10">
                            <Card className="d-flex justify-content-center">
                                <FormGroup row className="w-100">
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="name" className="mr-2">Name</Label>
                                                <Input type="text" name="name" placeholder="Full Name" id="name" value={formData.name.toUpperCase()} onChange={handleInputChange} invalid={!!formErrors.name} />
                                                {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="email" >Email</Label>
                                                <Input type="text" name="email" placeholder="Email Address" id="email" value={formData.email.toLowerCase()} onChange={handleInputChange} invalid={!!formErrors.email} />
                                                {formErrors.email && <div className="invalid-feedback">{formErrors.eamil}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="w-100" style={{ marginTop: -60 }}>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="mobile" className="mr-2">Mobile</Label>
                                                <Input type="text" name="mobile" placeholder="Mobile Number" id="mobile" value={formData.mobile} onChange={handleInputChange} invalid={!!formErrors.mobile} />
                                                {formErrors.mobile && <div className="invalid-feedback">{formErrors.mobile}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="user_status_id" className="mr-2">User Status</Label>
                                                <Input type="select" name="user_status_id" id="user_status_id" value={formData.user_status_id} onChange={handleInputChange} invalid={!!formErrors.user_status_id}>
                                                    <option value={0}>SELECT</option>
                                                    <option value={1} selected={formData.user_status_id == 1 ? true : false}>ACTIVE</option>
                                                    <option value={2} selected={formData.user_status_id == 2 ? true : false}>INACTIVE</option>
                                                    <option value={3} selected={formData.user_status_id == 3 ? true : false}>CLOSED</option>
                                                    <option value={4} selected={formData.user_status_id == 4 ? true : false}>BLOCKED</option>
                                                </Input>
                                                {formErrors.user_status_id && <div className="invalid-feedback">{formErrors.user_status_id}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="w-100" style={{ marginTop: -60 }}>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="gender" className="mr-2">Gender</Label>
                                                <Input type="select" name="gender" id="gender" value={formData.gender} onChange={handleInputChange} invalid={!!formErrors.gender}>
                                                    <option>SELECT</option>
                                                    <option value={"MALE"} selected={formData.gender == "MALE" ? true : false}>MALE</option>
                                                    <option value={"FEMALE"} selected={formData.gender == "FEMALE" ? true : false}>FEMALE</option>
                                                </Input>
                                                {formErrors.gender && <div className="invalid-feedback">{formErrors.gender}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="marital_status" className="mr-2">Marital Status</Label>
                                                <Input type="select" name="marital_status" id="marital_status" value={formData.marital_status} onChange={handleInputChange} invalid={!!formErrors.marital_status}>
                                                    <option>SELECT</option>
                                                    <option value={"MARRIED"} selected={formData.marital_status == "MARRIED" ? true : false}>MARRIED</option>
                                                    <option value={"UNMARRIED"} selected={formData.marital_status == "UNMARRIED" ? true : false}>UNMARRIED</option>
                                                </Input>
                                                {formErrors.marital_status && <div className="invalid-feedback">{formErrors.marital_status}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="w-100" style={{ marginTop: -60 }}>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="dob" className="mr-2">Date of Birth</Label>
                                                <Input type="date" name="dob" id="dob" value={formData.dob != "0001-01-01T00:00:00Z" ? formatDateYYYYMMDD(formData.dob) : ""} onChange={handleInputChange} invalid={!!formErrors.dob} />
                                                {formErrors.dob && <div className="invalid-feedback">{formErrors.dob}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="user_ip_check" className="mr-2">Check IP Address</Label>
                                                <Input type="select" name="user_ip_check" id="user_ip_check" value={formData.user_ip_check} onChange={handleInputChange} invalid={!!formErrors.user_ip_check}>
                                                    <option>Select IP Check</option>
                                                    <option value={1} selected={formData.user_ip_check == 1 ? true : false}>YES</option>
                                                    <option value={0} selected={formData.user_ip_check == 0 ? true : false}>NO</option>
                                                </Input>
                                                {formErrors.user_ip_check && <div className="invalid-feedback">{formErrors.user_ip_check}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="w-100" style={{ marginTop: -60 }}>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="user_static_ip" className="mr-2">Static IP Address</Label>
                                                <Input type="text" name="user_static_ip" id="user_static_ip" value={formData.user_static_ip} onChange={handleInputChange} invalid={!!formErrors.user_static_ip} />
                                                {formErrors.user_static_ip && <div className="invalid-feedback">{formErrors.user_static_ip}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form className="w-100">
                                            <CardBody>
                                                <Label for="father_name" className="mr-2">Father Name</Label>
                                                <Input type="text" name="father_name" id="father_name" value={formData.father_name} onChange={handleInputChange} invalid={!!formErrors.father_name} />
                                                {formErrors.father_name && <div className="invalid-feedback">{formErrors.father_name}</div>}
                                            </CardBody>
                                        </Form>
                                    </Col>

                                </FormGroup>
                            </Card>
                            <CardFooter className="text-center">
                                <button className="btn btn-outline-success">Add User</button>
                                <button onClick={navigateToUMS} className="btn btn-outline-danger" style={{ marginLeft: 10 }}>Cancel</button>
                            </CardFooter>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Col>

    );
}

export default AddUserForm;
