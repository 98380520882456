
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Card, Form, FormGroup, Col, Input } from 'reactstrap';
import { UploadDocuments, DeleteDocument, ViewDocuments, HeaderApi } from '../../api/index';
import { formatDateTime } from '../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchDocumentsData } from '../Task/Tasks';

const Documents = ({ lead_id }) => {
  const masterList = useSelector((state) => state.masters?.docsMaster || []);
  const customerDetails = useSelector((state) => state.customerDetails);
  const [data, setData] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [uniqueSubTypes, setUniqueSubTypes] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const userID = localStorage.getItem('user_id');
  const super_admin = localStorage.getItem('super_admin');
  const roles = useSelector((state) => state.auth.roles);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    document_id: '',
    file: null,
    password: '',
  });

  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProcessing(true);
        setApplicationStatus(customerDetails.data.lead.application_status || 0);
        setLeadData(customerDetails.data.lead || {});
      } catch (error) {
        console.error('Error fetching lead data:', error);
        toast.error('An unexpected error occurred while fetching lead data.');
      } finally {
        setProcessing(false);
      }
    };

    fetchData();
  }, [lead_id]);

  useEffect(() => {
    if (lead_id) {
      fetchData();
    }
  }, [lead_id]);


  const fetchData = async () => {
    try {
      const response = await fetchDocumentsData(lead_id);
      setData(response.data);
    } catch (error) {
      toast.error('Error fetching documents details');
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.document_id || !formData.file) {
      toast.error('Please select a document type and file');
      return;
    }
    setProcessing(true);
    const requestData = new FormData();
    requestData.append('lead_id', parseInt(lead_id, 10));
    requestData.append('document_id', parseInt(formData.document_id, 10));
    requestData.append('file', formData.file);
    requestData.append('password', formData.password);

    try {
      const response = await axios.post(`${UploadDocuments}`, requestData, { headers: HeaderApi });
      toast.success('Document uploaded successfully');
      setFormData({
        document_id: '',
        file: null,
        password: '',
      });
      fetchData();
      navigate(`${process.env.PUBLIC_URL}/lead-details/${lead_id}/${4}#documents`);
    } catch (error) {
      toast.error('Error uploading document');
    }
    setProcessing(false);
  };

  const handleView = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/0`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob(
        [response.data],
        { type: contentType });

      const fileURL = URL.createObjectURL(file);

      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 300000);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure you want to delete this document?");
    if (isConfirmed) {
      try {
        await axios.put(`${DeleteDocument}${id}`, null, {
          headers: HeaderApi
        });
        fetchData();
        toast.success('Document deleted successfully');
      } catch (error) {
        console.error('Error deleting document:', error);
        toast.error('Failed to delete document');
      }
    }
  };

  const handleDownload = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/0`, {
        headers: HeaderApi,
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/octet-stream' }); // the type is set to PDF

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // Open the URL on new Window
      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 10000);
      }

    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  useEffect(() => {
    const uniqueType = masterList.reduce((acc, current) => {
      acc[current.type] = current.type;
      return acc;
    }, {});

    setUniqueTypes(Object.keys(uniqueType));
  }, [data]);

  const handleDocsChange = (e) => {
    const selectedType = e.target.value;
    const subTypes = masterList.filter((doc) => doc.type === selectedType);
    setUniqueSubTypes(subTypes);
  };

  return (
    <>
      {((applicationStatus == 1 && leadData.stp_flag == 0 && (leadData.screener_assign_user_id == userID || leadData.credit_assign_user_id == userID) && leadData.stage !== "S7") || super_admin == "true") && (
        <Card>
          <Form style={{ marginTop: 10 }} onSubmit={handleSubmit}>
            <FormGroup row >
              <Col sm={2}>
                <Input type="select" onChange={handleDocsChange}>
                  <option value="">Select Type</option>
                  {uniqueTypes.map((docs, index) => (<option key={index} value={docs.ID}>{docs}</option>))}
                </Input>
              </Col>
              <Col sm={3}>
                <Input id="document_id" name="document_id" type="select" onChange={handleInputChange}>
                  <option value="">Select Type</option>
                  {uniqueSubTypes.map((docs, index) => (<option key={index} value={docs.ID}>{docs.sub_type}</option>))}
                </Input>
              </Col>
              <Col sm={3}>
                <Input
                  id={"file"}
                  name={"file"}
                  type={"file"}
                  onChange={handleFileChange}
                />

              </Col>
              <Col sm={2}>
                <Input
                  id={"password"}
                  name={"password"}
                  type={"password"}
                  placeholder={"Password"}
                  defaultValue={formData.password}
                  onChange={handleInputChange}
                />

              </Col>
              <Col sm={2}>
                {processing && (
                  <Button color="primary" type="submit" disabled={true}>UPLOAD...</Button>
                ) || (
                    <Button color="primary" type="submit" >UPLOAD</Button>
                  )
                }
              </Col>
            </FormGroup>
          </Form>
        </Card>
      ) || null}
      <table style={{ marginTop: 10 }} className="table table-hover table-striped table-bordered">
        <thead>
          <tr className="table-primary">
            <th scope="col"><b>Doc ID</b></th>
            <th scope="col"><b>Lead ID</b></th>
            <th scope="col"><b>Document&nbsp;Type</b></th>
            <th scope="col"><b>Document&nbsp;Name</b></th>
            <th scope="col"><b>Password</b></th>
            <th scope="col"><b>Uploaded&nbsp;By</b></th>
            <th scope="col"><b>Uploaded&nbsp;On</b></th>
            <th scope="col"><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? data.map((data) => (
            <tr key={data.ID}>
              <td>{data.ID}</td>
              <td>{data.lead_id ? data.lead_id : "-"}</td>
              <td>{data.master.type ? data.master.type : "-"}</td>
              <td>{data.master.sub_type ? data.master.sub_type : "-"}</td>
              <td>{data.password ? data.password : "-"}</td>
              <td>{data.upload_by.name}</td>
              <td>{data.CreatedAt ? formatDateTime(data.CreatedAt) : "-"}</td>
              <td>
                <a onClick={(e) => handleView(e, data.ID)}><i className="fa fa-eye" style={{ padding: "3px", color: "#35b7c4", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                <a onClick={(e) => handleDownload(e, data.ID)}><i className="fa fa-download" style={{ padding: "3px", color: "#00b01c", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                {(roles.includes(2) && leadData.lead_status_id <= 20 && lead_id == data.lead_id )&& (
                  <a onClick={(e) => handleDelete(e, data.ID)}><i className="fa fa-trash" style={{ padding: "3px", color: "#f70036", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                ) || null}
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="8" align={'center'} style={{ color: "#f40606" }}>No data available</td>
            </tr>
          )}
        </tbody>
      </table >
    </>
  );
};

export default Documents;
