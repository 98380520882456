import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import { Btn, H3, H4, H6, Image, P } from '../../../AbstractElements';
import { MyProfile, Bio, Password, Website, Save, EmailAddress, ConfirmPassword } from '../../../Constant';
import user from '../../../assets/images/user/7.jpg';

const MyProfileEdit = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [designation, setDesignation] = useState("");

  useEffect(() => {
    var userData = JSON.parse(localStorage.getItem("user_data"));
    setName(userData.name);
    setEmail(userData.email);
    setPhone(userData.phone);
    setAddress(userData.address);
    setDesignation(userData.designation);
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: 'card-title mb-0' }}>{MyProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="mb-2">
              <div className="profile-title">
                <Media>
                  <Image attrImage={{ className: 'img-70 rounded-circle', alt: '', src: `${user}` }} />
                  <Media body>
                    <H3 attrH3={{ className: 'mb-1 f-20 txt-primary' }}>{name}</H3>
                    <P>{designation}</P>
                  </Media>
                </Media>
              </div>
            </Row>
            <FormGroup className="mb-3">
              <Label className="form-label">{EmailAddress}</Label>
              <Input className="form-control" placeholder={email} disabled />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{Password}</Label>
              <Input className="form-control" type="password" defaultValue="" />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-label">{ConfirmPassword}</Label>
              <Input className="form-control" type="password" defaultValue="" />
            </FormGroup>
            <div className="form-footer">
              <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'button' }}>{Save}</Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default MyProfileEdit;
