import React, { useState, useEffect, useMemo } from 'react';
import { Col, Card, CardFooter, Row, Table, FormGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { HeaderApi, GetUserActivityLog, GetAllUsers } from '../../../api';
import Select from "react-select";
import { formatDateTime } from '../../../utils';

const rowsPerPage = 10;

const AssignRoles = () => {
    const [logCount, setLogCount] = useState(rowsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [logs, setLogs] = useState([]);

    useEffect(async () => {
        try {
            const response = await axios.post(GetAllUsers, undefined, { headers: HeaderApi });
            const userdata = response.data.data.map(user => ({
                label: (user.name + ' - ' + user.email).toUpperCase(),
                value: user.ID
            }));
            setUsers(userdata);
        } catch (error) {
            toast.error("Failed to fetch users" + error, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    }, []);

    useEffect(async () => {

        if (!selectedUserId) {
            return false;
        }
        try {
            const response = await axios.post(`${GetUserActivityLog}`, { user_id: parseInt(selectedUserId, 10), limit: rowsPerPage, offset: ((currentPage - 1) * rowsPerPage) }, { headers: HeaderApi });
            setLogs(response.data.data || []);
            setLogCount(response.data.total_count);
        } catch (error) {
            toast.error("Error fetching roles", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    }, [selectedUserId, currentPage]);

    const handleUserChange = async (selectedOption, { name }) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        if (selectedValue) setSelectedUserId(selectedValue);
    };

    const { canGoBack, canGoForward, totalPages, startPage, endPage } = useMemo(() => {
        const totalPages = Math.ceil(logCount / rowsPerPage);
        const canGoBack = currentPage > 1;
        const canGoForward = currentPage < totalPages;
        const maxPagesToShow = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
        let endPage = startPage + maxPagesToShow - 1;


        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxPagesToShow + 1, 1);
        }

        return { canGoBack, canGoForward, totalPages, startPage, endPage };
    }, [currentPage, logCount]);

    return (
        <Col sm="12">
            <Card>
                <Row className="card-block justify-content-center align-items-center">
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <h2 className="text-center">User Activity Logs</h2>
                    </Col>
                    <Col sm="12" className="d-flex justify-content-center align-items-center">
                        <FormGroup className="d-flex justify-content-center align-items-center" style={{ width: '100%', margin: '1rem' }}>
                            <Col md={4} >
                                <Select
                                    className="basic-single"
                                    value={users.find(user => user.value === selectedUserId)}
                                    isSearchable={true}
                                    name="user_id"
                                    id="userSelect"
                                    options={users}
                                    defaultOptions
                                    onChange={(selectedOption) => handleUserChange(selectedOption, { name: 'user_id' })}
                                />
                            </Col>
                        </FormGroup>
                    </Col>


                    <Col xs="12">
                        <CardFooter>
                            <Table className="table table-hover table-striped table-bordered">
                                <thead style={{ backgroundColor: "background-color: red" }}>
                                    <tr align={"center"} >
                                        <th >Log ID</th>
                                        <th>DateTime</th>
                                        <th>URL</th>
                                        <th>PlateForm</th>
                                        <th>Browser</th>
                                        <th>Activity</th>
                                        <th>Source</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    {logs.length > 0 ? logs.map((log, index) => (
                                        <tr key={log.ID}>
                                            <td>{log.ID}</td>
                                            <td>{log.CreatedAt ? formatDateTime(log.CreatedAt) : "-"}</td>
                                            <td>{log.url}</td>
                                            <td>{log.platform}</td>
                                            <td>{log.browser}</td>
                                            <td>{log.type_id === 1 ? "LOGIN" : "LOGOUT"}</td>
                                            <td>{log.source_type === 1 ? "WEB" : log.source_type === 1 ? "MOBILE" : "NA"}</td>
                                        </tr>
                                    )) :
                                        <tr><td colspan="8" align={'center'} style={{ color: "red", fontSize: 15 }}>Record not found</td> </tr>
                                    }
                                </tbody>
                            </Table>
                        </CardFooter>
                    </Col>
                    {logs.length > 0 ?
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Pagination aria-label="Page navigation example">
                                    <PaginationItem disabled={!canGoBack}>
                                        <PaginationLink onClick={() => setCurrentPage(1)} >First Page</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={!canGoBack}>
                                        <PaginationLink onClick={() => setCurrentPage(c => c - 1)} previous />
                                    </PaginationItem>
                                    {Array.from({ length: (endPage - startPage) + 1 }, (_, i) => (
                                        <PaginationItem key={startPage + i} active={startPage + i === currentPage}>
                                            <PaginationLink onClick={() => setCurrentPage(startPage + i)}>
                                                {startPage + i}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={!canGoForward}>
                                        <PaginationLink onClick={() => setCurrentPage(c => c + 1)} next />
                                    </PaginationItem>
                                    <PaginationItem disabled={!canGoForward}>
                                        <PaginationLink onClick={() => setCurrentPage(totalPages)} >Last Page</PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row> : null}
                </Row>
            </Card>
        </Col>

    );
};

export default AssignRoles;
