import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, Button } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';
import { HeaderApi, ImportLeads } from '../api/index';
import { useNavigate } from 'react-router-dom';

const ImportLeadsData = () => {
    const [uploadParams, setUploadParams] = useState(null);
    const navigate = useNavigate();

    const handleUpload = async () => {
        if (!uploadParams) {
            toast.error('No file uploaded.');
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadParams.file);

        const requestOptions = {
            method: 'POST',
            headers: {
                ...HeaderApi, // Spread the headers object from HeaderApi
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        };

        try {
            const response = await axios(ImportLeads, requestOptions);
            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                });
                navigate('/lead-new');
                // // Navigate to the dashboard after successful upload
                // setTimeout(() => {
                // }, 2000);
            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file. Please try again.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    };

    const getUploadParams = ({ file }) => {
        setUploadParams({ file });
        return { url: 'dummy' }; // URL is not needed since we use FormData
    };

    const handleDownload = () => {
        // Sample CSV data
        const csvData = [
            ['sr_no', 'name', 'pan_card', 'gender', 'mobile', 'alternate_mobile', 'email', 'alternate_email', 'applied_amount', 'applied_tenure', 'obligations', 'monthly_salary', 'enduse_id', 'state_id', 'city_id', 'pincode', 'term_and_condition', 'utm_source', 'utm_campaign', 'utm_medium'],
            ['1', 'Test', 'BTMPA1211A', 'MALE', '9876544400', '9876544444', 'abcdef@gmail.com', 'abcdefgij@gmail.com', '20000', '12', '100', '40000', '1', '10', '157', '1234546', '1', 'ABC', 'ABC', 'ABC']
          ];

        // Convert the data to CSV format
        const csvContent =
            'data:text/csv;charset=utf-8,' +
            csvData.map((e) => e.join(',')).join('\n');

        // Create a download link
        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvContent));
        link.setAttribute('download', 'sample.csv');

        // Append the link and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Fragment>
            <Row className="justify-content-center">
                <Col sm="6">
                    <Card className="mx-auto" style={{ marginTop: "50px" }}>
                        <CardHeader className="pb-0 d-flex justify-content-between">
                            <h5>Upload Leads</h5>
                            <Button color="btn-pill btn btn-primary" onClick={handleDownload}>Sample File</Button>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Row>
                                    <Col sm="12">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            maxFiles={1}
                                            multiple={false}
                                            canCancel={false}
                                            inputContent="Drop A File"
                                            accept=".csv"
                                            styles={{
                                                dropzoneActive: { borderColor: 'green' }
                                            }}
                                        />
                                    </Col>
                                    <Col sm="12" className="d-flex justify-content-center mt-3">
                                        <Button color="btn-pill btn btn-success" onClick={handleUpload}>Upload</Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ImportLeadsData;
