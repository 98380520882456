import axios from 'axios';
import { GetPersonalDetails, GetProfilePicture, HeaderApi, GetBankingDetails, GetDocsDetails, GetCAMDetails, GetEmploymentDetails, GetCollectionDetails } from '../../api';

const handleApiError = (error) => {
    if (error.response) {
        switch (error.response.status) {
            case 400:
                return { status: 0, message: "Bad request. Please check your input." };
            case 401:
                return { status: 0, message: "Unauthorized. Please log in again." };
            case 403:
                return { status: 0, message: "Forbidden. You don't have permission to access this data." };
            case 404:
                return { status: 0, message: "Data not found for this ID." };
            default:
                return { status: 0, message: `Server error: ${error.response.status}` };
        }
    } else if (error.request) {
        // The request was made but no response was received
        return { status: 0, message: "No response received from the server. Please try again later." };
    } else {
        // Something happened in setting up the request that triggered an Error
        return { status: 0, message: `Error setting up the request: ${error.message}` };
    }
};

const fetchPersonalData = async (id) => {
    const apiUrl = `${GetPersonalDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No personal data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        console.error('Error with the Axios request:', error);
        return handleApiError(error);
    }
};

const fetchBankingData = async (id) => {
    const apiUrl = `${GetBankingDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No banking data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        return handleApiError(error);
    }
};

const fetchDocumentsData = async (id) => {
    const apiUrl = `${GetDocsDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No banking data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        return handleApiError(error);
    }
};

const fetchCAMData = async (id) => {
    const apiUrl = `${GetCAMDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No banking data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        return handleApiError(error);
    }
};

const fetchEmployementData = async (id) => {
    const apiUrl = `${GetEmploymentDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No banking data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        return handleApiError(error);
    }
};

const fetchCollectionDetailsData = async (id) => {
    const apiUrl = `${GetCollectionDetails}${id}`;
    try {
        const response = await axios.get(apiUrl, { headers: HeaderApi });
        if (response.status === 204) {
            return { status: 0, message: "No banking data found for this ID." };
        }
        if (!response.data || !response.data.data) {
            throw new Error("Data not in expected format");
        }
        return { status: 1, data: response.data.data };
    } catch (error) {
        return handleApiError(error);
    }
};



export { fetchPersonalData, fetchBankingData, fetchDocumentsData, fetchCAMData, fetchEmployementData, fetchCollectionDetailsData };
