import { ForgotPassword } from '../../../api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { Mail } from 'react-feather';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label, CardFooter, CardBody } from 'reactstrap';
import { Btn, H4, H5 } from '../../../AbstractElements';
import { EmailAddress } from '../../../Constant';
import { Col, Row } from 'reactstrap';
import imgg from '../../../assets/images/login/1.jpg';

const ForgotPasswordView = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState({});

    const [name, setName] = useState("");

    useEffect(() => {
        localStorage.setItem('Name', name);
    }, [name]);

    const forgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { email }
        };

        try {
            const response = await axios(ForgotPassword, requestOptions);
            const userData = response.data;

            if (userData.status === 1) {
                setLoading(false);
                setEmail('');
                setErrors({});
                setSuccess({ success: userData.message });
            } else {
                setErrors({ error: userData.message });
            }
        } catch (error) {
            console.error('Error with the Axios request:', error);
            setLoading(false);
            setSuccess({});
            setErrors({ error: error.response.data.error });
        }
    };

    return (
        <Fragment>
            <CardBody fluid={true}>
                <Row>
                    <Col xl="7" style={{ backgroundImage: `url(${imgg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }} >
                    </Col>
                    <Col xl="5 p-0">
                        <div className="login-card1">
                            <Form className="theme-form login-form">
                                <div className="login-header text-center">
                                    <H4>{"Forgot Password"}</H4>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <h5 style={{ color: "red" }}>{errors.error ? errors.error : ""}</h5>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <h5 style={{ color: "green" }}>{success.success ? success.success : ""}</h5>
                                </div>
                                <div className="login-social-title">
                                    <H5></H5>
                                </div>
                                <br />
                                <FormGroup>
                                    <Label>{EmailAddress}</Label>
                                    <div className="input-group"><span className="input-group-text"><Mail /></span>
                                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} placeholder="Username" />
                                    </div>
                                </FormGroup>
                                <FormGroup className="login-btn form-group">
                                    <div className="checkbox">
                                        {/* <Input id="checkbox1" type="checkbox" />
                                        <Label className="text-muted" for="checkbox1">{Login}</Label> */}
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}/login`} className="link">
                                        {"Back To Login"}?
                                    </Link>
                                </FormGroup>
                                <CardFooter className="text-center">
                                    <Btn attrBtn={{ color: 'primary', className: 'btn-block', disabled: loading ? loading : loading, onClick: (e) => forgotPassword(e) }} >{loading ? 'LOADING...' : "SUBMIT"}</Btn>
                                </CardFooter>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Fragment>
    );
};

export default ForgotPasswordView;
