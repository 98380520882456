function formatDateTime(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours.toString().padStart(2, '0') : '12'; // Convert hour '0' to '12'

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
}

function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours.toString().padStart(2, '0') : '12'; // Convert hour '0' to '12'

    return `${day}-${month}-${year}`;
}

const formatDateYYYYMMDD = (dateString) => {
    if (!dateString) return '';
    const d = new Date(dateString);
    let month = ('0' + (d.getMonth() + 1)).slice(-2);
    let day = ('0' + d.getDate()).slice(-2);
    let year = d.getFullYear();
    return [year, month, day].join('-');
};

const calculateDateDifference = (date1, date2) => {
    let parsedDate1 = new Date(formatDateYYYYMMDD(date1));
    let parsedDate2 = new Date(formatDateYYYYMMDD(date2));

    let differenceInMilliseconds = Math.abs(parsedDate2 - parsedDate1);
    let differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

    return differenceInDays;
};

// Encrypt data before storing in localStorage
async function encryptData(secretKey, data) {
    const encodedData = new TextEncoder().encode(data);
    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: window.crypto.getRandomValues(new Uint8Array(12))
        },
        secretKey,
        encodedData
    );
    return {
        encryptedData: Array.from(new Uint8Array(encryptedData)), // convert to array for storage
        iv: Array.from(new Uint8Array(encryptedData.slice(0, 12))) // slice out the initialization vector
    };
}

// Decrypt data retrieved from localStorage
async function decryptData(secretKey, encryptedData, iv) {
    const decryptedData = await window.crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: new Uint8Array(iv)
        },
        secretKey,
        new Uint8Array(encryptedData)
    );
    return new TextDecoder().decode(decryptedData);
}

async function generateKey() {
    return window.crypto.subtle.generateKey(
        {
            name: "AES-GCM",
            length: 256
        },
        true,
        ["encrypt", "decrypt"]
    );
}

async function storeData(key, data) {
    const { encryptedData, iv } = await encryptData(key, data);
    localStorage.setItem('encryptedData', JSON.stringify(encryptedData));
    localStorage.setItem('iv', JSON.stringify(iv));
}

async function retrieveData(key) {
    const encryptedData = JSON.parse(localStorage.getItem('encryptedData'));
    const iv = JSON.parse(localStorage.getItem('iv'));
    if (encryptedData && iv) {
        return await decryptData(key, encryptedData, iv);
    }
    return null; // or handle the absence of data
}

function mask_mobile(mobile) {
    if (mobile) {
        return mobile.toString().slice(0, -4).replace(/./g, '*') + mobile.toString().slice(-4);
    }
    return '-';
}

function mask_email(email) {
    if (email) {
        const atIndex = email.indexOf('@');
        const localPart = email.slice(0, atIndex);
        const domainPart = email.slice(atIndex);
        const halfLength = Math.floor(localPart.length / 2); // Determine half of the local part's length, rounding down
        const maskedLocal = localPart.slice(0, halfLength).replace(/./g, '*') + localPart.slice(halfLength);
        return maskedLocal + domainPart;
    }
    return '-';
}

function arraysHaveAnyCommonElements(arr1, arr2) {
    return arr1.some(value => arr2.includes(value));
}

const roundToTwo = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};

export { formatDateTime, formatDate, formatDateYYYYMMDD, calculateDateDifference, encryptData, decryptData, generateKey, storeData, retrieveData, mask_mobile, mask_email, roundToTwo, arraysHaveAnyCommonElements };
