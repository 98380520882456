import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { IsValidUser, HeaderApi } from '../api';

const PrivateRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Parse the item from local storage and default to false if not set
        const item = localStorage.getItem('authenticated');
        return item ? JSON.parse(item) : false;
    });

    useEffect(() => {
        let isComponentMounted = true;

        const authenticateUser = async () => {
            try {
                const response = await axios.post(IsValidUser, {}, { headers: HeaderApi });
                const userData = response.data;

                if (isComponentMounted) {
                    if (userData.status === 1) {
                        localStorage.setItem('authenticated', 'true');
                        setIsAuthenticated(true);
                    } else {
                        localStorage.removeItem('authenticated');
                        setIsAuthenticated(false);
                    }
                }
            } catch (error) {
                if (isComponentMounted) {
                    localStorage.removeItem('authenticated');
                    setIsAuthenticated(false);
                }
            }
        };

        authenticateUser();

        // Cleanup function to prevent state updates when the component is unmounted
        return () => {
            isComponentMounted = false;
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
        isAuthenticated ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} />
    );
};

export default PrivateRoute;
