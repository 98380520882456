
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { GetBankingDetails, HeaderApi, GetBankVerificationStatusList, SearchIFSC, SaveBankingDetails, GetAccountTypeList, ViewDocuments } from '../../api';
import { Card, CardBody, Form, FormGroup, Input, Label, Media, Row, Col, CardFooter, Container, Button, FormFeedback } from 'reactstrap';
import axios from 'axios'
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { formatDateTime } from '../../utils';
import { toast } from 'react-toastify';
import { fetchBankingData, fetchPersonalData } from '../Task/Tasks';


const Update = "SAVE BANKING DETAILS";
const Edit = "ADD BANKING DETAILS";

const Banking = ({ lead_id }) => {
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const bankVerificationList = useSelector(state => state.masters.accountVerificationStatusMaster);
  const accountType = useSelector(state => state.masters.accountTypeMaster);
  const [processing, setProcessing] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(0);
  const userID = localStorage.getItem('user_id');
  const [roleIds, setRoleIds] = useState(() => {
    const storedRoleIds = localStorage.getItem('role_ids');
    return storedRoleIds ? JSON.parse(storedRoleIds) : [];
  });
  const [leadData, setLeadData] = useState({});
  const customerDetails = useSelector((state) => state.customerDetails);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProcessing(true);
        // const result = await fetchPersonalData(lead_id);
        const result = customerDetails;
        // if (result.status === 1) {
          setLeadData(result.data.lead || {});
          setApplicationStatus(result.data.lead.application_status);
        // } else {
        //   toast.error(result.message);
        // }
      } catch (error) {
        console.error('Error fetching lead data:', error);
        toast.error('An unexpected error occurred while fetching lead data.');
      } finally {
        setProcessing(false);
      }
    };

    fetchData();
  }, [lead_id]);

  const [formData, setFormData] = useState({
    account_number: "",
    confirm_number: "",
    ifsc_code: "",
    beneficiary_name: "",
    account_type: "",
    bank_name: "",
    branch_name: "",
    verification_status: "",
    verification_screenshot: "",
    remarks: ""
  });


  const fetchData = useCallback(async () => {
    let apiUrl = GetBankingDetails + lead_id;
    if (apiUrl) {
      try {
        const response = await fetchBankingData(lead_id);
        setData(response.data);
      } catch (error) {
        console.error('Error with the Axios request:', error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        setData([]);
      }
    }
  }, [lead_id]);

  useEffect(() => {
    if (lead_id) fetchData();
  }, [fetchData]);

  const handleFileChange = (e) => {
    setFormData({ ...formData, verification_screenshot: e.target.files[0] });
  };

  const onEditSubmit = async (e) => {
    e.preventDefault();

    const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
    setFormErrors(errors);
    if (!Object.values(errors).some(error => error)) {
      setProcessing(true);
      const requestData = new FormData();
      requestData.append('lead_id', parseInt(lead_id, 10));
      requestData.append('account_number', formData.account_number);
      requestData.append('confirm_number', formData.confirm_number);
      requestData.append('ifsc_code', formData.ifsc_code);
      requestData.append('bank_name', formData.bank_name);
      requestData.append('branch_name', formData.branch_name);
      requestData.append('account_type', parseInt(formData.account_type, 10));
      requestData.append('beneficiary_name', formData.beneficiary_name);
      requestData.append('verification_status', parseInt(formData.verification_status, 10));
      requestData.append('remarks', formData.remarks);
      requestData.append('file_name', formData.verification_screenshot);
      try {
        const response = await axios.post(`${SaveBankingDetails}`, requestData, { headers: HeaderApi });
        toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        fetchData();
        setFormData({});
      } catch (error) {
        toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      }
      setEditMode(false);
      setProcessing(false);
    }
  };

  const handleView = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/1`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob(
        [response.data],
        { type: contentType });

      const fileURL = URL.createObjectURL(file);

      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 300000);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const handleDownload = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/1`, {
        headers: HeaderApi,
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/octet-stream' }); // the type is set to PDF

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // Open the URL on new Window
      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 10000);
      }

    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const validateField = (name, value) => {
    const valueString = value.toString().trim();
    switch (name) {
      case 'verification_status': return valueString.trim() ? '' : 'Status is required';
      case 'account_number': return valueString.trim() ? '' : 'Account Number is required';
      case 'confirm_number': return valueString.trim() ? (formData.account_number == value ? '' : 'Account Numbers do not match') : 'Confirm Account Number is required';
      case 'beneficiary_name': return valueString.trim() ? '' : 'Beneficiary is required';
      case 'account_type': return valueString.trim() ? '' : 'Status is required';
      case 'bank_name': return valueString.trim() ? '' : 'Bank Name is required';
      case 'branch_name': return valueString.trim() ? '' : 'Branch Name is required';
      case 'remarks': return valueString.trim() ? '' : 'Remarks is required';
      default: return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.target.value = value.toUpperCase();

    setFormData((formData) => ({
      ...formData,
      [name]: value.toUpperCase()
    }));

    if (name === "remarks") {
      setFormData((formData) => ({
        ...formData,
        remarks: value.toUpperCase()
      }));
    }

    setFormErrors((errors) => ({
      ...errors,
      [name]: validateField(name, value),
      ...name === 'account_number' ? { confirm_number: validateField('confirm_number', formData.confirm_number) } : {}
    }));
  };

  const handleSelectChange = async (selectedOption, { name }) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedValue,
    }));

    setFormErrors((errors) => ({
      ...errors,
      [name]: validateField(name, selectedValue),
    }));

    if (selectedValue) {
      try {
        const response = await axios.post(SearchIFSC, { ifsc_code: selectedValue }, { headers: HeaderApi });
        const options = response.data.data[0];
        setFormData((prevState) => ({
          ...prevState,
          branch_name: options.branch,
          bank_name: options.name,
        }));
      } catch (error) {
        console.error('Error fetching IFSC details:', error);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        branch_name: '',
        bank_name: '',
      }));
    }
  };


  const loadIfscCodes = async (inputValue, callback) => {
    if (!inputValue) callback([]);
    try {
      const response = await axios.post(SearchIFSC, { ifsc_code: inputValue }, { headers: HeaderApi });
      const options = response.data.data.map(ifsc_data => ({
        label: ifsc_data.ifsc,
        value: ifsc_data.ifsc,
      }));
      callback(options);
    } catch (error) {
      console.error('Error fetching IFSC codes:', error);
      callback([]);
    }
  };

  const renderInput = useCallback((key, value) => {
    switch (key) {
      case "verification_status":
        return (
          <Input id="verification_status" name="verification_status" type="select" Value={formData.verification_status} invalid={!!formErrors['verification_status']} onChange={handleInputChange} >
            <option value="">Select Bank Status</option>
            {bankVerificationList.map((bank, index) => (<option key={index} value={bank.ID}>{bank.name}</option>))}
          </Input>
        );
      case "account_type":
        return (
          <Input id="account_type" name="account_type" type="select" Value={formData.account_type} invalid={!!formErrors['account_type']} onChange={handleInputChange} >
            <option value="">Select Type</option>
            {accountType.map((type, index) => (<option key={index} value={type.ID}>{type.name}</option>))}
          </Input>
        );
      case "ifsc_code":
        return (
          <AsyncSelect
            name={key}
            cacheOptions
            loadOptions={loadIfscCodes}
            onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'ifsc_code' })}
            defaultOptions
            isClearable={false}
          />
        );
      case "verification_screenshot":
        return (
          <Input
            id={key}
            type="file"
            name={key}
            onChange={handleFileChange}
          />
        );
      case "branch_name":
        return (
          <Input
            id={key}
            name={key}
            Value={formData.branch_name}
            invalid={!!formErrors[key]}
            disabled={true}
          />
        );
      case "bank_name":
        return (
          <Input
            id={key}
            name={key}
            Value={formData.bank_name}
            invalid={!!formErrors[key]}
            disabled={true}
          />
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            defaultValue={value}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange]);

  const renderFormGroups = Object.entries(formData).reduce((acc, entry, index, array) => {
    if (index % 2 === 0) {
      acc.push(array.slice(index, index + 2));
    }
    return acc;
  }, []).map((pair, index) => (
    <FormGroup row key={index}>
      {pair.map(([key, value]) => (
        <Fragment key={key}>
          <Label for={key} sm={2}>
            {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
          </Label>
          <Col sm={4}>
            {renderInput(key, value)}
            {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
          </Col>
        </Fragment>
      ))}
    </FormGroup>
  ));

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              {data.length > 0 ? data.map((item, index) => (
                item.account_status_id === 1 && (
                  <div key={index}>
                    <table className="table table-bordered table-striped">
                      <tbody>
                        <tr>
                          <th>Beneficiary Name</th>
                          <td>{item.beneficiary_name}</td>
                          <th>Verified ON</th>
                          <td>{item.UpdatedAt ? formatDateTime(item.UpdatedAt) : "-"}</td>
                        </tr>
                        <tr>
                          <th>Bank A/C No.</th>
                          <td>{item.account}</td>
                          <th>IFSC Code</th>
                          <td>{item.ifsc_code}</td>
                        </tr>
                        <tr>
                          <th>Bank A/C Type</th>
                          <td>{item.account_type.name}</td>
                          <th>Bank Name</th>
                          <td>{item.bank_name}</td>
                        </tr>
                        <tr>
                          <th>Branch Name</th>
                          <td>{item.branch}</td>
                          <th>Verification Status</th>
                          <td style={{ color: "green" }}>{item.account_status.name}</td>
                        </tr>
                        <tr>
                          <th>Verification Screenshot</th>
                          <td>
                            <a onClick={(e) => handleView(e, item.ID)}><i className="fa fa-eye" style={{ padding: "3px", color: "#35b7c4", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                            <a onClick={(e) => handleDownload(e, item.ID)}><i className="fa fa-download" style={{ padding: "3px", color: "#35b7c4", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              )) : null}
            </Card>
            <Card>
              <Form onSubmit={onEditSubmit}>
                <CardBody>
                  {editMode ? (
                    <>
                      {renderFormGroups.length > 0 ? renderFormGroups : null}
                    </>
                  ) : (
                    <Fragment>
                      <Row className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr className="table-primary">
                              <th className="text-nowrap">#</th>
                              <th className="text-nowrap">Lead&nbsp;ID</th>
                              <th className="text-nowrap">Beneficiary&nbsp;Name</th>
                              <th className="text-nowrap">Bank&nbsp;A/C&nbsp;No.</th>
                              <th className="text-nowrap">IFSC&nbsp;Code</th>
                              <th className="text-nowrap">Bank&nbsp;A/C&nbsp;Type</th>
                              <th className="text-nowrap">Bank&nbsp;Name</th>
                              <th className="text-nowrap">Branch&nbsp;Name</th>
                              <th className="text-nowrap">Active&nbsp;Account</th>
                              <th className="text-nowrap">Remark</th><th className="text-nowrap">Created&nbsp;ON</th>
                              <th className="text-nowrap">Updated&nbsp;ON</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 ? data.map((data) => (
                              <tr align='center' key={data.ID}>
                                <td className="text-nowrap">{data.ID}</td>
                                <td className="text-nowrap">{data.lead_id}</td>
                                <td className="text-nowrap">{data.beneficiary_name}</td>
                                <td className="text-nowrap">{data.account}</td>
                                <td className="text-nowrap">{data.ifsc_code}</td>
                                <td className="text-nowrap">{data.account_type.name}</td>
                                <td className="text-nowrap">{data.bank_name}</td>
                                <td className="text-nowrap">{data.branch}</td>
                                <td className="text-nowrap">{data.account_type_id ? "YES" : "NO"}</td>
                                <td className="text-nowrap">{data.remark}</td>
                                <td className="text-nowrap">{data.CreatedAt ? formatDateTime(data.CreatedAt) : "-"}</td>
                                <td className="text-nowrap">{data.UpdatedAt ? formatDateTime(data.UpdatedAt) : "-"}</td>
                              </tr>
                            )) : (
                              <tr>
                                <td colSpan="12" align={'center'} style={{ color: "#f40606" }}>No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Row>
                    </Fragment>
                  )}
                </CardBody>
                {editMode && (
                  <CardFooter className="text-end">

                    {processing && (
                      <Button color="btn btn-outline-success" type="submit" disabled={true}>Saving Banking Details...</Button>
                    ) || (
                        <Button color="btn btn-outline-success" type="submit">{editMode ? Update : Edit}</Button>
                      )}
                    <Button color="btn btn-outline-danger" style={{ marginLeft: 10 }} onClick={() => setEditMode(!editMode)}>CANCEL</Button>
                  </CardFooter>
                )}
              </Form>
              {!editMode && (
                <>
                  {(applicationStatus == 1 && leadData.stp_flag == 0 && leadData.credit_assign_user_id == userID && roleIds.includes(4) && leadData.lead_status_id < 14 && leadData.stage !== "S7") && (
                    <CardFooter className="text-center">
                      <Button color="btn btn-outline-info" onClick={() => setEditMode(!editMode)}>{editMode ? Update : Edit}</Button>
                    </CardFooter>
                  ) || ""}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
};

export default Banking;
