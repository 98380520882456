import axios from 'axios';
import { useEffect } from 'react';
import { MenuListApi, HeaderApi } from '../../api/index';
import { HomeSvg } from '../../Data/svgIcons';

const isLoggedIn = localStorage.getItem('authenticated');


const fetchMenuItems = async () => {
    try {
        const requestOptions = {
            headers: HeaderApi
        };

        const response = await axios.get(MenuListApi, requestOptions); // Replace 'MenuList' with your API endpoint
        const menuData = response.data;

        if (menuData.status === 1) {
            return parseMenuData(menuData.data);
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error with the Axios request:', error);
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        return [];
    }
};

const parseMenuData = (data) => {

    const items = Object.values(data).reduce((acc, curr) => {

        const key = Object.keys(curr)[0];
        const item = curr[key];

        if (!item) {
            console.error('Invalid item structure:', curr);
            return acc;
        }

        const { menu_name, route_link, menu_sub_name, stage, icon } = item;
        const existingItem = acc.find(item => item.menutitle === menu_name);

        if (existingItem) {
            existingItem.Items[0].children.push({
                title: menu_sub_name,
                icon: icon,
                type: 'link',
                path: `${process.env.PUBLIC_URL}/${route_link}`
            });
        } else {
            acc.push({
                menutitle: menu_name,
                Items: [
                    {
                        title: menu_name,
                        icon: icon,
                        type: 'sub',
                        active: false,
                        children: [
                            {
                                title: menu_sub_name,
                                icon: icon,
                                type: 'link',
                                path: `${process.env.PUBLIC_URL}/${route_link}`
                            }
                        ]
                    }
                ]
            });
        }
        return acc;
    }, []);

    return items;
};

if (isLoggedIn) {
    fetchMenuItems().then(menuItems => {
        MENUITEMS.push(...menuItems);
    });
}

export const MENUITEMS = [];
