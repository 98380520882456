// import React from 'react';

// const Spinner = (props) =>(
//   <div {...props.attrSpinner}  />
// );

// export default Spinner;

import React from 'react';
// import './YourStylesheet.css'; // Ensure you import the stylesheet where you have defined the spinner styles

const Spinner = ({ attrSpinner, text }) => (
  <div {...attrSpinner} style={{ position: 'relative', ...attrSpinner.style, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {/* Spinner element with the animated spinner icon */}
    <div className="spinner"></div>

    {/* Text overlay */}
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'black', // Ensure the text color contrasts with the spinner
      textAlign: 'center',
      fontSize: '1rem',
      zIndex: 2, // Ensure the text is above the spinner
    }}>
      {text || 0}
    </div>
  </div>
);

export default Spinner;
