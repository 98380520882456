import React, { useState, useEffect } from 'react';
import { Col, Card, CardFooter, Row, Button, FormGroup, Label, Input, Form, CardBody } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { HeaderApi, ChangePassword, GetAllUsers } from '../../../api';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const AssignRoles = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const super_admin = localStorage.getItem('super_admin');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: '',
        user_id: id
    });

    useEffect(async () => {
        try {
            const response = await axios.post(GetAllUsers, undefined, { headers: HeaderApi });
            const userdata = response.data.data.map(user => ({
                label: user.name,
                value: user.ID
            }));
            setUsers(userdata);
        } catch (error) {
            toast.error("Failed to fetch users" + error, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
            if (error.response?.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: validateField(key, formData[key]) }), {});
        setFormErrors(errors);
        console.log("formData", errors)
        if (!Object.values(errors).some(error => error)) {
            try {
                const response = await axios.post(ChangePassword, { user_id: parseInt(formData.user_id, 10), new_password: formData.password, confirm_password: formData.confirm_password }, { headers: HeaderApi });
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                navigateToUMS();
            } catch (error) {
                toast.error(error.response?.data?.error || 'Error occurred', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                if (error.response?.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
            ...name === 'password' ? { confirm_password: validateField('confirm_password', formData.confirm_password) } : {}
        }));
    };

    const validateField = (name, value) => {
        const valueString = String(value).trim();
        switch (name) {
            case 'user_id': return valueString.trim() ? '' : 'User ID is missing';
            case 'password': return valueString.trim() ? '' : 'Password is required';
            case 'confirm_password': return value.trim() ? (formData.password === value ? '' : 'Passwords do not match') : 'Confirm Password is required';
            default: return '';
        }
    };

    const navigateToUMS = () => navigate(`${process.env.PUBLIC_URL}/ums/user-list`);

    const handleUserChange = async (selectedOption, { name }) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        if (selectedValue) setSelectedUserId(selectedValue);
    };

    return (
        <Col sm="12">
            <Form onSubmit={handleSubmit}>
                <Card>
                    <Row className="card-block justify-content-center align-items-center">
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            &nbsp;
                        </Col>
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            <h2 className="text-center">Change Password</h2>
                        </Col>
                        <Col sm="12" className="d-flex justify-content-center align-items-center">
                            <FormGroup className="d-flex justify-content-center align-items-center" style={{ width: '100%', margin: '1rem' }}>
                                <Col md={4} >

                                </Col>
                            </FormGroup>
                        </Col>

                        <Col xl="8">
                            <Card className="d-flex justify-content-center" xl={6}>
                                <FormGroup row>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="password" >New Password</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="password"
                                                        type="text"
                                                        name="password"
                                                        onChange={handleInputChange}
                                                        value={formData.password}
                                                        invalid={!!formErrors.password}
                                                    />
                                                    {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <CardBody>
                                                <Label for="confirm_password" >Confirm Password</Label>
                                                <Col sm={10}>
                                                    <Input
                                                        id="confirm_password"
                                                        type="text"
                                                        name="confirm_password"
                                                        onChange={handleInputChange}
                                                        value={formData.confirm_password}
                                                        invalid={!!formErrors.confirm_password}
                                                    />
                                                    {formErrors.confirm_password && <div className="invalid-feedback">{formErrors.confirm_password}</div>}
                                                </Col>
                                            </CardBody>
                                        </Form>
                                    </Col>
                                </FormGroup>
                            </Card>
                            <CardFooter className="text-center">
                                <Button type="submit" color="btn btn-outline-info">Change Password</Button>
                                <Button color="btn btn-outline-danger" onClick={navigateToUMS} style={{ marginLeft: 10 }} >Cancel</Button>
                            </CardFooter>
                        </Col>

                    </Row>
                </Card>
            </Form>
        </Col>

    );
};

export default AssignRoles;
